import Vue from "vue";

/*TITLE*/
document.title = "Walter Haus Aquilino | Porcelanosa PARTNERS";
/*PROJECT TYPE*/
Vue.prototype.$typeProject = "PARTNERS";

/*HERO*/
Vue.prototype.$title = "Walter Haus Aquilino";
Vue.prototype.$subtitle = "MADRID";

/*INTRO*/
Vue.prototype.$promoter = "Walter Haus Aquilino";
Vue.prototype.$introSubtitle = "MADRID";

/*GALLERY*/
Vue.prototype.$image_sittingroom = "0-livingroom--20221124091628.jpeg";
Vue.prototype.$image_kitchen = "0-kitchen--20221124091628.jpeg";
Vue.prototype.$image_bath1 = "0-bathroom1--20221124091628.jpeg";
Vue.prototype.$image_bath2 = "0-bathroom2--20221124091628.jpeg";
Vue.prototype.$image_room = "";
Vue.prototype.$image_facade = "";

Vue.prototype.$gallery_images_sittingroom = [
  {
    src: "0-livingroom--20221124091628.jpeg",
  },
];

Vue.prototype.$gallery_images_kitchen = [
  {
    src: "0-kitchen--20221124091628.jpeg",
  },
];

Vue.prototype.$gallery_images_bath1 = [
  {
    src: "0-bathroom1--20221124091628.jpeg",
  },
];

Vue.prototype.$gallery_images_bath2 = [
  {
    src: "0-bathroom2--20221124091628.jpeg",
  },
];

Vue.prototype.$gallery_images_room = [
];

Vue.prototype.$gallery_images_facade = [
];

/*GALLERY GRID*/
Vue.prototype.$gallery_grid_sittingroom = "6";
Vue.prototype.$gallery_grid_kitchen = "6";
Vue.prototype.$gallery_grid_bath1 = "3";
Vue.prototype.$gallery_grid_bath2 = "3";
Vue.prototype.$gallery_grid_room = "12";
Vue.prototype.$gallery_grid_facade = "12";

/*TOUR VIRTUAL*/
Vue.prototype.$360 = "";

/*3D FILM*/
Vue.prototype.$3dFilm = "";

/*CATALOG*/
Vue.prototype.$catalog = true;

/*SPACES*/
Vue.prototype.$spaces = "https://spaces.porcelanosa.com/tag/aquilinodominguez/?e=iframe";

/*PROMOTION LOGO*/
Vue.prototype.$promotion_logo = false;

/*PROMOTER LOGO*/
Vue.prototype.$promoter_logo = true;

/*CONTACT*/
Vue.prototype.$promoter_address = [
  {
    icon: "mdi-map-marker",
    link: "https://maps.google.com/?q=calle%20aquilino%20dominguez%207%20madrid%2028020",
    text: "Calle Aquilino Dominguez 7, Madrid, 28020",
  },
];

Vue.prototype.$showroom = [
  {
    icon: "mdi-map-marker",
    link: "https://maps.google.com/?q=paseo%20gral%20martinez%20campos%2013%2028010%20madrid",
    text: "Paseo Gral Martinez Campos 13 - 28010, Madrid",
  },
];

Vue.prototype.$contact = [
  {
    icon: "mdi-phone",
    link: "telf:(+34)917166716",
    text: "(+34)917166716",
  },
  {
    icon: "mdi-email",
    link: "mailto:chamberi@walterhaus.es",
    text: "chamberi@walterhaus.es",
  },
];
